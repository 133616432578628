import React from 'react';
import ReactDOM from 'react-dom';
import AuthProvider from './contexts/authContext';
import { LearningSpaceProvider } from './contexts/learningSpaceContext';
import { ToastProvider } from './contexts/toastContext';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <React.StrictMode>
    <ToastProvider>
      <AuthProvider>
        <LearningSpaceProvider>
          <App />
        </LearningSpaceProvider>
      </AuthProvider>
    </ToastProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
