import React from "react";
import { withTheme } from "@material-ui/core/styles";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import {
  logo,
  bellIcon,
  caret,
  homeIcon,
  courseIcon,
  marketplaceIcon,
} from "../../assets/svgs";
import Avatar from "../common/Avatar";
import Pill from "../common/Pill";

const DesktopNavigation: React.FC<any> = () => {
  return (
    <NavContentContainer>
      <div>
        <img src={logo} alt="logo" />
      </div>
      <LinksContainer>
        <Links to="/" exact>
          <img src={homeIcon} alt="home-icon" />
          Learning Spaces
        </Links>
        {/* <Links to="/courses" exact>
          <img src={courseIcon} alt="course-icon" />
          Courses
        </Links> */}
        {/* <Links to="/marketplace" exact>
          <img src={marketplaceIcon} alt="marketplace-icon" />
          Marketplace
        </Links> */}
      </LinksContainer>
      <RightNavContainer>
        <Container>
          <img src={bellIcon} alt="notification-bell" />
        </Container>
        <Container>
          <Avatar url={null} name={"mike"} />
        </Container>
        <Container>
          <Pill text="inactive" />
        </Container>
        <Container>
          <img src={caret} alt="caret" />
        </Container>
      </RightNavContainer>
    </NavContentContainer>
  );
};
const activeClassName = "nav-item-active";
const NavContentContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const RightNavContainer = styled.div`
  display: flex;
  align-items: center;
`;
const Links = withTheme(styled(NavLink).attrs({ activeClassName })`
  &.${activeClassName} {
    color: ${(props) => props.theme.colors.white};
    font-weight: ${(props) => props.theme.fontWeights.bold};
  }
  &:hover{
    background: rgba(5, 18, 29, 0.33);
  }
  display:flex;
  align-items:center
  font-weight: ${(props) => props.theme.fontWeights.normal};
  transition: all 0.3s;
  text-decoration: none;
  color: ${(props) => props.theme.colors.white};
  margin: 0 30px;
  padding: 6px 12px;
  border-radius: ${(props) => props.theme.radius.normal}px;
  & img {
    margin-right: 8px;
  }
`);
const LinksContainer = styled.div`
  display: flex;
`;
const Container = styled.div`
  padding: 0 10px;
`;

export default DesktopNavigation;
