import React, { useState, useCallback } from 'react';
import { sendRequest } from 'config/api';
import { useToast } from './toastContext';

const LearningSpaceContext = React.createContext(null);

export const LearningSpaceProvider = (props) => {
  const [learningSpaces, setLearningSpaces] = useState([]);
  const [loadingLearningSpace, setLoadingLearningSpace] = useState(false);
  const [learingSpaceDetails, setLearningSpaceDetails] = useState({});
  const [learningSpaceUsers, setLearningSpaceUsers] = useState({});
  const [loadingLearningSpaceUsers, setLoadingLearningSpaceUsers] =
    useState(false);
  const [deletingLearningSpace, setDeletingLearningSpace] = useState(false);
  const [editingLearningSpace, setEditingLearningSpace] = useState(false);
  const [updatingSubscription, setUpdatingSubscription] = useState(false);
  const [editingUser, setEditingUser] = useState(false);
  const [fetchingMoreLearningSpaces, setFetchingMoreLearningSpaces] =
    useState(false);

  const { toast } = useToast();

  const getLearningSpaces = useCallback(async (searchParams = 'zon') => {
    setLoadingLearningSpace(true);
    try {
      const { data } = await sendRequest(
        `/org/company/domains/?search=${searchParams}`
      );
      setLearningSpaces(data);
    } catch (error) {
      console.log(error);
    }
    setLoadingLearningSpace(false);
  }, []);

  const learningSpacesFetchMore = async (pageNumber, searchParams="") => {
    console.log('more')
    try {
      const { data } = await sendRequest(
        `/org/company/domains/?search=${searchParams}&page=${pageNumber}`
      );
      setLearningSpaces(prevState => ({
        
        ...data,
        results: prevState?.results?.concat(data.results)
      }))
     
    } catch (error) {
      console.log(error);
    }
  };

  const getLearningSpaceDetails = useCallback(async () => {
    setLoadingLearningSpace(true);
    try {
      const { data } = await sendRequest('/org/company/data/?domain=andela');
      console.log(data);
      setLearningSpaceDetails(data);
    } catch (error) {
      // alert(JSON.stringify(error.message));
    }
    setLoadingLearningSpace(false);
  }, []);

  const getSwitchAdminCompany = async (userId, learningSpaceId) => {
    return sendRequest(
      `/auth/users/${userId}/update_company/`,
      { company: learningSpaceId },
      'PATCH'
    )
      .then((response) => response)
      .catch((error) => error);
  };

  const getLearningSpaceUsers = useCallback(async (userId, learningSpaceId, pageNumber = 1, itemsPerPage = 10) => {
    setLoadingLearningSpaceUsers(true);
    getSwitchAdminCompany(userId, learningSpaceId)
      .then(() => sendRequest(`/auth/users/?page=${pageNumber}&&page_size=${itemsPerPage}`))
      .then(({ data }) => setLearningSpaceUsers(data))
      .catch((error) => console.log(error)) //add a toast to handle errors later
      .finally(() => setLoadingLearningSpaceUsers(false));
  }, []);

  const deleteLearningSpaces = useCallback((companyId) => {
    setDeletingLearningSpace(true);
    return sendRequest(`/org/company/${companyId}`, null, 'delete')
      .then(() => toast.success('Deleted successfully'))
      .catch((error) => toast.error("Couldn't delete learning space"))
      .finally(() => setDeletingLearningSpace(false));
  }, []);

  const editLearningSpace = useCallback((companyId, editInfo) => {
    setEditingLearningSpace(true);
    return sendRequest(`/org/company/${companyId}/`, editInfo, 'patch')
      .then(() => {
        toast.success('Updated successfully');
        getLearningSpaceDetails(companyId);
      })
      .catch((error) => toast.error("Couldn't update learning space"))
      .finally(() => setEditingLearningSpace(false));
  });

  const updateSubscription = useCallback((subscriptionId, subscriptionInfo) => {
    setEditingLearningSpace(true);
    return sendRequest(
      `/subscription/subscribe/${subscriptionId}/`,
      subscriptionInfo,
      'patch'
    )
      .then(() => {
        toast.success('Updated successfully');
        getLearningSpaceDetails(subscriptionId);
      })
      .catch((error) => toast.error("Couldn't update learning space"))
      .finally(() => setEditingLearningSpace(false));
  });

  const editUser = useCallback((userId, editInfo, userMode = 'ADMIN') => {
    setEditingUser(true);
    return sendRequest(
      `/auth/users/${userId}/?user_mode=${userMode}`,
      editInfo,
      'PATCH',
      'multipart/form-data'
    )
      .then(() => {})
      .catch(() => {})
      .finally();
  }, []);

  const value = {
    data: {
      learningSpaces,
      loadingLearningSpace,
      learingSpaceDetails,
      loadingLearningSpaceUsers,
      learningSpaceUsers,
      deletingLearningSpace,
      editingLearningSpace,
      updatingSubscription,
      editingUser,
    },
    actions: {
      getLearningSpaces,
      getLearningSpaceDetails,
      getLearningSpaceUsers,
      deleteLearningSpaces,
      editLearningSpace,
      updateSubscription,
      editUser,
      learningSpacesFetchMore
    },
  };
  return (
    <LearningSpaceContext.Provider value={value}>
      {props.children}
    </LearningSpaceContext.Provider>
  );
};

export const useLearningSpaceContext = () =>
  React.useContext(LearningSpaceContext);

export default LearningSpaceContext;
