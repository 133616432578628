import React, { useContext, useState } from 'react';
import jwt_decode from 'jwt-decode';

export const AuthContext = React.createContext();

export const useAuthContext = () => {
  return useContext(AuthContext);
};

export const AuthProvider = (props) => {
  const [userInfo, setUserInfo] = useState(null);
  let authState = false;
  if (localStorage.access) {
    const decoded = jwt_decode(localStorage.access);
    if (!userInfo) {
      setUserInfo(decoded);
    }
    const currentTime = Date.now() / 1000;
    if (decoded.exp < currentTime) {
      authState = false;
    } else {
      authState = true;
    }
  }
  const [isAuthenticated, setIsAuthenticated] = useState(authState);
  const updateIsAuthenticated = (value) => setIsAuthenticated(() => value);

  return (
    <AuthContext.Provider
      value={{ isAuthenticated, updateIsAuthenticated, userInfo }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
