import React from "react";
import styled from "styled-components";
import { withTheme } from "@material-ui/core/styles";

interface IAvatar {
  url: string | null;
  name: string;
}
type AvatarContainerProps = { url: string };

const Avatar: React.FC<IAvatar> = ({ url, name }) => {
  return (
    <AvatarContainer url={url}>
      {url ? null : <span>{name.charAt(0).toLocaleUpperCase()}</span>}
    </AvatarContainer>
  );
};
const AvatarContainer = withTheme(styled.div<AvatarContainerProps>`
  background-color: ${(props) => props.theme.colors.lightPurple};
  background-image: ${(props) => `url(${props.url})`};
  background-repeat: no-repeat;
  background-size: cover;
  height: 36px;
  width: 36px;
  border-radius: 50%;
  color: ${(props) => props.theme.colors.purple};
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: ${(props) => props.theme.fontWeights.bold};
  font-size: ${(props) => props.theme.fontSizes.large + 1}px;
`);

export default Avatar;
