import React, { useState, useCallback } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ToastContext = React.createContext(null);

export const ToastProvider = ({ children }) => {
  const value = {
    toast
  }
  return (
    <ToastContext.Provider value={value}>
      <ToastContainer
        autoClose={5000}
        // CheckMark={CheckMark}
        // CloseToast={CloseToast}
        position='top-center'
      />
      {children}
    </ToastContext.Provider>
  );
};

export const useToast = () => React.useContext(ToastContext);
