import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import DesktopNavigation from "./desktopNavigation";

const Navigation: React.FC<any> = () => {
  const classes = useStyles();
  return (
    <div className={classes.navigationBackGroundContainer}>
      <div className={classes.navigationInnerContainer}>
        <DesktopNavigation />
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme: any) => {
  return {
    navigationBackGroundContainer: {
      background: theme.colors.blue,
      padding: "20px 0",
    },
    navigationInnerContainer: {
      maxWidth: "1400px",
      margin: "0 auto",
    },
  };
});

export default Navigation;
