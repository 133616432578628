import { Suspense } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core/styles';
import { constants } from 'constants/index';
import Navigation from './components/Navigation';
import { publicRoutes, privateroutes } from './routes';
import PrivateRoute from './components/PrivateRoute';
import { useAuthContext } from './contexts/authContext';
import setAuthToken from './utils/setAuthToken';
import jwt_decode from 'jwt-decode';

const queryClient = new QueryClient();
function App() {
  const { updateIsAuthenticated } = useAuthContext();

  // Check for token
  if (localStorage.access) {
    // Set the auth token header auth
    setAuthToken(localStorage.access);
    // Decode token and get user info and exp
    const decoded: any = jwt_decode(localStorage.access);
    // Set user and isAuthenticated
    updateIsAuthenticated(true);
    // Check for expired token

    const currentTime = Date.now() / 1000;
    if (decoded.exp < currentTime) {
      // Logout user
      updateIsAuthenticated(false);
      // [TODO] Clear current
      // store.dispatch(clearCurrentProfile());
      // Redirect to login
      window.location.href = '/login';
    } else {
      updateIsAuthenticated(true);
    }
  }

  return (
    <ThemeProvider theme={{ ...constants }}>
      <QueryClientProvider client={queryClient}>
        <Router>
          <Navigation />
          <Suspense fallback={'loading'}>
            <Switch>
              {publicRoutes.map((route, index) => (
                <Route
                  path={route.url}
                  component={route.component}
                  key={index}
                  exact={route.exact}
                />
              ))}
            </Switch>
            <Switch>
              {privateroutes.map((route, index) => {
                return (
                  <PrivateRoute
                    exact={route.exact ? route.exact : false}
                    path={route.url}
                    component={route.component}
                    key={index}
                  />
                );
              })}
            </Switch>
          </Suspense>
        </Router>
      </QueryClientProvider>
    </ThemeProvider>
  );
}

export default App;
