import React from 'react';
import { useAuthContext } from '../../contexts/authContext';
import { Route, Redirect } from 'react-router-dom';

interface IPrivateRoute {
  exact?: boolean;
  path?: string;
  component?: any;
  key?: number;
}

const PrivateRoute: React.FC<IPrivateRoute> = ({
  component: Component,
  ...rest
}) => {
  const { isAuthenticated } = useAuthContext();

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? <Component {...props} /> : <Redirect to='/login' />
      }
    />
  );
};

export default PrivateRoute;
