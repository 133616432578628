const colors = {
  black: "#1A1A1A",
  black100: "#161531",
  white: "#FFFFFF",
  grey: "#E7E7E7", //would stick to greey
  primary100: "#E3F9AB",
  primary200: "#CAF457",
  primary300: "#BCE351", //what does primary mean???
  secondary: "#439EEF", //what does secondary mean???
  blue: "#0C2539",
  red: "#FF6464",
  green: "#74D691",
  orange: "#F0A942",
  gray100: "#F6F6F6",
  gray200: "#F0F0F0",
  gray300: "#E5E5E5",
  gray400: "#828282",
  gray500: "#4F4F4F",
  gray600: "3D3D3D",
  gray700: "#F2F2F2",
  gray800: "#F3F3F4",
  lightPurple: "#F1F2FF",
  purple: "#4850AE",
  lightPink: "#FAEFFF",
  pink: "#BA26FF",
};

const radius = {
  normal: 18,
  small: 9,
};

const shadow = {
  default: "0px 4px 4px rgba(231, 231, 231, 0.2)",
};

const fontSizes = {
  xlarge: 28,
  large: 18,
};

const fontWeights = {
  light: 300,
  normal: 400,
  medium: 500,
  semiBold: 600,
  bold: 700,
  bolder: 900, //might pull out....
};

const spaces = {
  large: 30,
  normal: 20,
  small: 16, //this is just random for now
};

export const constants = {
  colors,
  radius,
  shadow,
  fontSizes,
  fontWeights,
  spaces,
};
