import { lazy } from 'react';
const Login = lazy(() => import('./pages/Auth/Login'));
const ForgotPassword = lazy(() => import('./pages/Auth/ForgotPassword'));
const LearningSpaces = lazy(() => import('pages/LearningSpaces'));
const LearningSpaceDetail = lazy(
  () =>
    import('pages/LearningSpaces/learning-space-details/LearningSpaceDetails')
);
const Courses = lazy(() => import('pages/Courses/'));

interface IRoute {
  url: string;
  component: any;
  exact?: boolean;
}
export const privateroutes: IRoute[] = [
  {
    url: '/',
    component: LearningSpaces,
    exact: true,
  },
  {
    url: '/learning-space/:learningSpaceId',
    component: LearningSpaceDetail,
  },
  {
    url: '/learning-spaces',
    component: LearningSpaces,
    exact: true,
  },
  {
    url: '/courses',
    component: Courses,
    exact: true,
  },
];

export const publicRoutes: IRoute[] = [
  {
    url: '/login',
    component: Login,
    exact: true,
  },
  {
    url: '/forgot-password',
    component: ForgotPassword,
    exact: true,
  },
  // {
  //   url: '/',
  //   component: LearningSpaces,
  // },
];
