import React from "react";
import styled from "styled-components";
import { withTheme } from "@material-ui/core/styles";
interface IPill {
  text: string;
}
type PillContainerProps = { text: string };
const selectPillStyle: any = (props: any) => {
  let pillStyle;
  switch (props.children) {
    case "admin":
      pillStyle = {
        color: props.theme.colors.black100,
        background: props.theme.colors.white,
      };
      break;
    case "professor":
      pillStyle = {
        color: props.theme.colors.pink,
        background: props.theme.colors.lightPink,
      };
      break;
    case "student":
      pillStyle = {
        color: props.theme.colors.lightPurple,
        background: props.theme.colors.purple,
      };
      break;
    case "active":
      pillStyle = {
        color: props.theme.colors.white,
        background: props.theme.colors.green,
      };
      break;
    case "inactive":
      pillStyle = {
        color: props.theme.colors.white,
        background: props.theme.colors.red,
      };
      break;
  }
  console.log(pillStyle);

  return pillStyle;
};
const Pill: React.FC<IPill> = ({ text }) => {
  return <PillContainer>{text}</PillContainer>;
};
const PillContainer = withTheme(styled.div<PillContainerProps>`
  padding: 3px 12px;
  text-transform: capitalize;
  border-radius: 7px;
  border: none;
  ${(props) => selectPillStyle(props)};
`);

export default Pill;
